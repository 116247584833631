<template>
    <div class="center" >
        <div class="center">
            <div class="md-headline" style="text-align: center">{{lng[lg].iniciar_sesion}}</div>
        </div>
        <form @submit.prevent="check_establecimiento">
            <md-field :class="{'md-invalid' : error.show}">
                <label>{{lng[lg].property_id}}</label>
                <md-input v-model="establecimiento_id" autofocus></md-input>
                <span class="md-error" >{{error.text}}</span>
            </md-field>
            <md-button class="btn-w-100 md-raised md-primary" @click="check_establecimiento">{{lng[lg].iniciar}}</md-button>
        </form>
    </div>
</template>
<script>
	import {request, Utility} from "../../helpers";
	export default   {
        props:['lang'],
        name : 'login-establecimiento',
        data(){
            return({
                establecimiento_id:'',
				error :{
					show : false,
					text : ''
				},
				lng : {
					es : {
						iniciar_sesion : 'Iniciar sessión',
						property_id : 'ID de la propiedad',
						lenguaje : 'Lenguaje',
						iniciar : 'INICIAR',
                        error_sev : 'Error, no se ha podido conectar con el servicio',
					},
					en : {
						iniciar_sesion : 'Log in',
						property_id : 'Property ID',
						lenguaje : 'Language',
						iniciar : 'START',
                        error_sev : 'Error, could not connect to the service',
					},
					fr :{
						iniciar_sesion : 'Commencer la session',
						property_id : 'ID de propriété',
						lenguaje : 'Langue',
						iniciar : 'Commencer',
                        error_sev : 'Erreur, impossible de se connecter au service',
					}
				}
            })
        },
		computed:{
			lg(){ return this.$store.state.active_lang}
		},
		beforeMount() {
			if(this.lang) return this.$store.state.active_lang = this.lang;
		},
		methods :{
            async check_establecimiento(){
            	this.error = {
					show : false,
					text : ''
				};

                if(this.establecimiento_id){
                	this.$store.state.loading = true;

                	let data = {
						lg: this.$store.state.active_lang,
						codigoHotel: this.establecimiento_id
					};

					let response = await  request(base_url+'/entrar/validarhotel?view=json&jwt='+this.$store.state.jwt, Utility.convertModelToFormData(data), false);
					if(response.ok) {
                        let slug = response.r.data.slug;
                        this.$store.slug = slug;
						this.$router.push({ name: 'login-usuario', params:{slug: slug ,lg : this.lang}})
					}else{
					    console.log(response);
                        if(!response.r) {
                            this.$store.commit('setSnackBar', {
                                show: true,
                                text: this.lng[this.lg].error_sev
                            });
                        }else if(response.status = 406){
							this.error = {
								show : true,
								text : response.r.data.message
							};
						}

					}

					this.$store.state.loading = false;
                }
            }
        }
    };
</script>
<style scoped>
    .md-field {
        width: 100%;
        min-height: 48px;
        margin: 42px 0;
        padding-top: 16px;
        display: flex;
        position: relative;
        font-family: inherit;
    }
    button{
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
</style>
